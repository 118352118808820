<template>
  <div>
    <el-page-header @back="dynamic" content="用户配置" style="margin-bottom: 15px;">
    </el-page-header>
    <el-button type="text">
      <el-button plain size="mini">清空访问记录</el-button>
    </el-button>
    <el-table
        :data="visitorData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="visIp"
          label="访问地址"
          width="150">
      </el-table-column>
      <el-table-column
          prop="visOs"
          label="操作系统"
          width="150">
      </el-table-column>
      <el-table-column
          prop="visInfo"
          label="浏览器"
          width="150">
      </el-table-column>

      <el-table-column
          prop="rsn"
          label="归属地">
        <template>暂未获取/未知</template>
      </el-table-column>
      <el-table-column
          prop="visTime"
          label="访问时间"
          width="150">
      </el-table-column>
      <el-table-column
          label="操作"
          width="50">
        <template>
          <el-button
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "userInfo",
  data() {
    return {
      formLabelWidth: '80px',
      visitorData: []
    }
  },
  mounted() {
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },

  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>